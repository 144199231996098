
@media  screen and (min-width: 525px) {
    .count-block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .count-countroll {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .count-title-mobile {
        display: none;
    }

    .count-button {
        width: 24px;
        height: 24px;
        background: #ECECF0;
        border-radius: 6px;
        border: none;
        margin-left: 16px;
        margin-right: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .count-type {
        color: #A4A3B0;
        font-size: 20px;
    }

    .count-value {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 29px;
        text-align: center;
        color: #000000;   
    }
}

@media  screen and (max-width: 525px) {
    .count-block {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .count-title-desktop {
        display: none;
    }

    .count-countroll {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .count-button {
        width: 24px;
        height: 24px;
        background: #ECECF0;
        border-radius: 6px;
        border: none;
        margin-left: 16px;
        margin-right: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .count-type {
        color: #A4A3B0;
        font-size: 20px;
    }

    .count-value {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 29px;
        text-align: center;
        color: #000000;
        min-width: 23px;
    }

}