.choose-places {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.choose-places-title {
    font-family: 'OnestRegular';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #000000;
}

.choose-place-legend {
    width: 220px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.legend {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'OnestThin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;    
}

.legend-icon {
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    border-radius: 5px;
    margin-right: 10px;
}

.legend-icon-free {
    border: 1px solid #31CA57;
}

.legend-icon-busy {
    background: #F2F3F6;
}

.bus-scheme {
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: flex-start;
    margin-top: 40px;
}

.ticket-seats-list {
    /* display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start; */
}

.seats-driver {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 5px;
}

.bus-floor {
    padding: 15px;
    border: 1px solid #DDDEE5;
    border-radius: 30px;
}

.seats-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.empty-row {
    min-width: 30px;
    min-height: 30px;
}

.seats-item-link {
    text-decoration: none;
    color: #000000;
}

.seats-item {
    width: 40px;
    height: 40px;
    background: #F2F3F6;
    border-radius: 5px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'OnestThin';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
}

.bus-space {
    width: 40px;
    height: 40px;
    margin: 5px;
}

.seats-free {
    background: #FFFFFF;
    color: #000000;
    border: 1px solid #31CA57;
}

.seats-busy {
    background: #F2F3F6;
    color: #A4A3B0;
}

.choose-places-controller {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.choose-places-controller-link {
    width: 100%;
    text-decoration: none;
}

.choose-places-button {
    width: 100%;
    height: 45px;
    border-radius: 8px;
    font-family: 'OnestRegular';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    border: none;
    margin-bottom: 20px;
}

.choose-confirm {
    background: #31CA57;
    color: #FFFFFF;
}

.choose-confirm[disabled] {
    background: rgb(180, 177, 177);
    color: #FFFFFF;
}

.choose-other {
    border: 2px solid #31CA57;
    color: #31CA57;
    background: #ffffff;
}


@media  screen and (min-width: 525px) {
    .choose-places-title {
        font-size: 30px;
        line-height: 38px;
    }

    .legend {
        font-size: 18px;
        line-height: 23px;
    }
    .bus-floor {
        display: flex;
        flex-direction: row-reverse;
    }

    .seats-list {
        flex-direction: column;
    }

    .seats-driver {
        transform: rotate(90deg);
    }

    .choose-places-controller {
        width: 500px;
        flex-direction: row-reverse;
    }

    .choose-places-button {
        /* width: 220px; */
        max-width: 220px;
        font-size: 20px;
        line-height: 25px;
        margin: 20px;
    }

    .seats-row {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }
}