/* Футер */

@media  screen and (min-width: 525px) {
	.footer-container {
		width: 100%;
		height: 376px;
		padding-top: 70px;
		padding-bottom: 70px;
		border-top: 1px solid #DDDEE5;
	    display: flex;
	    flex-direction: row; 
	    justify-content: center; 
	    align-items: center; 
	}

	.footer {
		width: 1180px;
		display: flex;
	    flex-direction: row;
	    justify-content: space-between; 
	    align-items: flex-start; 
	}

	.footer-chapter {
		display: flex;
	    flex-direction: column;
	    justify-content: flex-start; 
	    align-items: flex-start; 
	}

	.menu-block-1 {
		width: 417px;
	}

	.contacts {
		height: 100%;
	}

	.messangers {
		display: flex;
	    flex-direction: row;
	    justify-content: space-between; 
	    align-items: flex-start;
	    width: 135px;
	}

	.navigation-copyright {
		width: 640px;
		display: flex;
	    flex-direction: column;
	    justify-content: space-between; 
	    align-items: flex-start; 
	}

	.navigation {
		width: 100%;
		height: 100%;
		display: flex;
	    flex-direction: row;
	    justify-content: flex-start; 
	    align-items: flex-start; 
	}

	.footer-chapter-title {
		font-family: OnestThin;
		font-size: 18px;
		font-weight: 400;
		line-height: 23px;
		letter-spacing: 0em;
		text-align: left;
		padding-bottom: 40px;
	}

	.footer-chapter-item {
		font-family: OnestThin;
		font-size: 23px;
		font-weight: 400;
		line-height: 29px;
		letter-spacing: 0em;
		text-align: left;

	}

	.footer-chapter-phone {
		font-family: OnestThin;
		font-size: 23px;
		font-weight: 400;
		line-height: 29px;
		letter-spacing: 0em;
		text-align: left;
		padding-bottom: 20px;

	}

	.footer-chapter-email {
		font-family: OnestThin;
		font-size: 23px;
		font-weight: 400;
		line-height: 29px;
		letter-spacing: 0em;
		text-align: left;
		padding-bottom: 35px;
	}

	.acquiring-copyright {
		width: 100%;
		padding-top: 104px;
	    display: flex;
	    flex-direction: row; 
	    justify-content: flex-end; 
	    align-items: flex-end; 
	}

	.acquiring {
	    display: flex;
	    flex-direction: row; 
	    justify-content: space-between; 
	    align-items: center;
	    padding-right: 20px
	}

	.acquiring-logo {
		padding-right: 20px;
	}

	.copyright {
		font-family: OnestThin;
		width: 209px;
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: left;
	}

	.footer-link {
		text-decoration: none;
		color: #000000;
	}
}


@media  screen and (max-width: 525px) {
	.footer-container {
		width: 100%;
		padding-top: 35px;
		padding-bottom: 20px;
		border-top: 1px solid #DDDEE5;
	    display: flex;
	    flex-direction: column; 
	    justify-content: center; 
	    align-items: center; 
	}

	.footer {
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}

	.footer-chapter {
		display: flex;
	    flex-direction: column;
	    justify-content: flex-start; 
	    align-items: flex-start; 
	}

	.menu-block-1 {
		width: 205px;
	}

	.contacts {
		padding-bottom: 35px;
	}

	.messangers {
		display: flex;
	    flex-direction: row;
	    justify-content: space-between; 
	    align-items: flex-start;
	}

	.messanger-icon-mobile {
		height: 50px;
		padding-right: 10px;
	}

	.navigation-copyright {

	}

	.navigation {
		width: 100%;
		height: 100%;
		display: flex;
	    flex-direction: row;
	    justify-content: flex-start; 
	    align-items: flex-start; 
		padding-bottom: 40px;
	}

	.footer-chapter-title {
		font-family: OnestThin;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: #000000;
		padding-bottom: 15px;
	}

	.footer-chapter-item {
		font-family: OnestRegular;
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 23px;
		color: #000000;
	}

	.footer-chapter-phone {
		font-family: OnestThin;
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 23px;
		color: #000000;
		padding-bottom: 15px;
	}

	.footer-chapter-email {
		font-family: OnestThin;
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 23px;
		color: #000000;
		padding-bottom: 15px;
	}

	.acquiring-copyright {
		width: 100%;
	    display: flex;
	    flex-direction: row-reverse; 
	    justify-content: space-between; 
	    align-items: flex-end;
	}

	.acquiring {
	    display: flex;
	    flex-direction: row; 
	    justify-content: space-between; 
	    align-items: center;
	}

	.acquiring-logo {
		height: 27px;
		padding-right: 13px;
	}

	.acquiring-logo-visa {
		height: 13px;
	}

	.copyright {
		font-family: OnestThin;
		width: 161px;
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
	}

	.footer-link {
		text-decoration: none;
		color: #000000;
	}
}