/*
	Column center

	display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;  


	Row space beetween 

    display: flex;
    flex-direction: row; 
    justify-content: space-between; 
    align-items: center; 
*/

@font-face {
	font-family: "OnestRegular";
	src: url('./fonts/onest/TTF/OnestRegular1602-hint.ttf');
}

@font-face {
	font-family: "OnestThin";
	src: url('./fonts/onest/WOFF/OnestThin1602-hint.woff');
}
	
@media  screen and (min-width: 525px) {
    .body-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center; 
        align-items: center;
    }

    .page-head {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #273041;
        color: #FFFFFF;
    }

	.page-container {
		width: 1180px;
	}

    .page-title-block {
        width: 1180px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 90px;
        margin-bottom: 60px;
    }

    .page-title {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 700;
        font-size: 60px;
        line-height: 70px;
        color: #FFFFFF;
    }
}


@media  screen and (max-width: 525px) {
    .body-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center; 
        align-items: center;  
    }

    .page-head {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #273041;
        color: #FFFFFF;
        padding-left: 15px;
        padding-right: 15px;
    }

	.page-container {
		width: 100%;
        padding-left: 15px;
        padding-right: 15px;
	}

    .page-title-block {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .page-title {

        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 700;
        font-size: 35px;
        line-height: 40px;
        color: #FFFFFF;
    }
}





















