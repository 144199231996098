.carriers-block {
    font-family: 'OnestRegular';
    font-style: normal;
    font-weight: 400;
    color: #000000;
    margin-top: 40px;
    margin-bottom: 60px;
}

.carriers-title {
    font-size: 18px;
    line-height: 26px;
    text-align: justify;
}

.carriers-steps {
    margin-top: 30px;
}

.carriers-steps-title {
    font-size: 18px;
}

.carriers-steps-list {
    margin-top: 17px;
}

.carriers-steps-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
}

.carriers-steps-item-image {
    width: 16px;
    height: 11px;
    margin-right: 15px;
}

.carriers-steps-item-value {
    font-family: 'OnestThin';
    font-size: 14px;
    line-height: 24px;
}

.carriers-form-block {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #DDDEE5;
    border-radius: 20px;
    padding: 30px 20px 40px 20px;
    margin-top: 40px;
}

.carriers-form-main {
    margin-bottom: 15px;
}

.carriers-form-title {
    font-size: 20px;
    line-height: 25px;
}

.carriers-form-fields {
    margin-top: 20px;
    margin-bottom: 10px;
}

.carriers-form-input {
    border: 1px solid #DDDEE5;
    border-radius: 10px;   
    width: 100%;
    height: 60px;
    margin-bottom: 10px;
}

.carriers-form-button {
    width: 100%;
    height: 60px;
    background: #31CA57;
    border-radius: 10px;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
    border: none;
}

.carriers-form-button[disabled] {
    background-color: #2fad4a;
}

.carriers-form-personal-info {
    font-size: 14px;
    color: #A4A3B0;
}

.carriers-form-personal-info-link {
    color: #31CA57;
}

@media  screen and (min-width: 525px) {
    .carriers-block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 60px;
        margin-bottom: 100px;
    }

    .carriers-title {
        font-size: 18px;
        line-height: 33px;
        text-align: justify;
    }

    .carriers-info {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-right: 60px;
    }

    .carriers-form-block {
        margin-top: 0px;
        width: 540px;
    }

    .carriers-steps {
        margin-top: 40px;
    }

    .carriers-steps-title {
        font-size: 23px;
        line-height: 33px;
    }

    .carriers-steps-list {
        margin-top: 24px;
    }

    .carriers-steps-item-image {
        width: 21px;
        height: 14px;
        margin-right: 20px;
    }

    .carriers-steps-item-value {
        font-size: 18px;
        line-height: 33px;
    }

    .carriers-form-block {
        padding: 50px 60px 50px 60px;
    }

    .carriers-form-title {
        font-size: 30px;
        line-height: 38px;
    }

    .carriers-form-input {
        width: 420px;
        height: 80px;
        border-radius: 15px;
        margin-bottom: 15px;
    }

    .carriers-form-main {
        margin-top: 30px;
    }

    .carriers-form-fields {
        margin-bottom: 15px;
    }

    .carriers-form-button {
        width: 420px;
        height: 80px;;
        border-radius: 15px;
        font-size: 23px;
        line-height: 29px;
    }

    .carriers-form-personal-info {
        font-size: 14px;
        line-height: 18px;
    }
}