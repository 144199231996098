	/* Блок з перевагами */

@media  screen and (min-width: 525px) {
	.company-goals {
		height: 1120px;
		padding-top: 100px;
		padding-bottom: 100px;
		display: flex;
	    flex-direction: column;
	    justify-content: space-between;
	}

	.company-goals-card  {
		height: 450px;
		border: 1px solid #DDDEE5;
		border-radius: 15px;
	}

	.company-goals-row {
		display: flex;
	    flex-direction: row;
	    justify-content: space-between;
	    align-items: center;
	}

	.goals-card-image {
		width: 100%;
	    display: flex;
	    flex-direction: row; 
	    justify-content: center; 
	    align-items: center; 
	}

	.goals-card-title {
		font-family: OnestRegular;
		font-size: 30px;
		font-weight: 700;
		line-height: 38px;
		letter-spacing: 0em;
		text-align: left;
	}

	.goals-card-title-top {
		padding-left: 30px;
		padding-top: 30px;
	}

	.goals-card-title-bottom {
		padding-left: 30px;
		padding-bottom: 30px;
	}

	.company-goals-trip {
		width: 780px;
		display: flex;
	    flex-direction: column;
	    justify-content: flex-end; 
	    align-items: flex-start; 
	}

	.company-goals-bonus {
		width: 380px;
		display: flex;
	    flex-direction: column;
	    justify-content: space-between; 
	    align-items: flex-start; 
		display: flex;
	}

	.company-goals-ticket {
		width: 580px;
		display: flex;
	    flex-direction: column;
	    justify-content: flex-end; 
	    align-items: flex-start;
	}

	.company-goals-ticket-image {
		padding-bottom: 26px;
	}

	.company-goals-reviews {
		width: 580px;
		display: flex;
	    flex-direction: column;
	    justify-content: flex-start; 
	    align-items: flex-start;
	}
}


@media  screen and (max-width: 525px) {
	.company-goals {
		padding-top: 40px;
		padding-bottom: 60px;
	}

	.company-goals-card  {
		width: 100%;
		/* height: 260px; */
		border: 1px solid #DDDEE5;
		border-radius: 15px;
		margin-bottom: 10px;
	}

	.goals-card-image-mobile {
		display: flex;
	    flex-direction: column-reverse;
	    justify-content: flex-end; 
	    align-items: flex-start;
	}

	.company-goals-row {
		display: flex;
	    flex-direction: column;
	    justify-content: space-between;
	    align-items: center;
	}

	.goals-card-image {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.goals-card-image-trip-mobile {
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 32px;
	}

	.goals-card-title-trip-mobile {
		margin-bottom: 28px;
	}

	.goals-card-title {
		width: 100%;
		font-family: 'OnestRegular';
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 25px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-top: 23px;
		text-align: center;
	}

	/* .goals-card-title-top {

	}

	.goals-card-title-bottom {

	}

	.company-goals-trip {

	}

	.company-goals-bonus {

	}

	.company-goals-ticket {

	}

	.company-goals-ticket-image {

	}

	.company-goals-reviews {

	} */
}