	/* Блок з пошуком квитків */

	@media  screen and (min-width: 525px) {
		.search-block {
			width: 1180px;
			padding-top: 125px;
			padding-bottom: 80px;
			display: flex;
			flex-direction: column; 
			justify-content: flex-start; 
			align-items: flex-start; 
		}
	
		.search-block-on-ticket-page {
			padding-top: 30px;
			padding-bottom: 30px;
		}
	
		.search-title {
			font-family: OnestRegular;
			font-size: 60px;
			font-weight: 700;
			line-height: 70px;
			letter-spacing: 0em;
			text-align: left;
			padding-bottom: 50px;
		}
	
		.search-form {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			/* padding-bottom: 80px; */
		}
	
		/* .search-form-on-ticket-page {
			padding-bottom: 0px;
		} */
	
		.form-field {
			height: 100%;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
		}
	
		.search-form-fields {
			width: 940px;
			height: 80px;
			background-color: #FFFFFF;
			border-radius: 15px;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			color: #A4A3B0;
		}
	
		.form-field-from {
			width: 226px;
			border-right: 1px solid #DDDEE5;
			padding-left: 20px;
			font-family: 'OnestThin';
			font-style: normal;
			font-weight: 400;
			font-size: 23px;
			line-height: 29px;
		}
	
		.form-field-to {
			width: 232px;
			border-right: 1px solid #DDDEE5;
			padding-left: 20px;
			font-family: 'OnestThin';
			font-style: normal;
			font-weight: 400;
			font-size: 23px;
			line-height: 29px;
		}
	
		.form-field-date {
			width: 232px;
			border-right: 1px solid #DDDEE5;
			padding-left: 20px;
	
			font-family: 'OnestThin';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 20px;
			color: #A4A3B0;
		}
	
		.form-field-place {
			width: 232px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
	
			font-family: 'OnestThin';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 20px;
			color: #A4A3B0;
		}
	
		.search-form-button {
			width: 230px;
			height: 80px;
			background-color: #31CA57;
			border-radius: 15px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			color: #FFFFFF;
			outline: none;
			border: none;
		}
	
		.search-form-button[disabled] {
			width: 230px;
			height: 80px;
			background-color: #2fad4a;
			border-radius: 15px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			color: #FFFFFF;
			outline: none;
			border: none;
		}
	

		.search-form-button-icon {
			left: 1376px;
			top: 346px;
			border-radius: 0px;
			padding-right: 13px;
		}
	
		.search-form-button-title {
			font-family: 'OnestRegular';
			font-style: normal;
			font-size: 23px;
			font-weight: 700;
			line-height: 29px;
			letter-spacing: 0em;
			text-align: left;
		}
	
		.search-form-button-link {
			width: 100%;
			text-decoration: none;
			color: #FFFFFF;
			margin-left: 10px;
		}
	}
	
	@media  screen and (max-width: 525px) {
		.search-block {
			width: 100%;
			padding-top: 57px;
			padding-bottom: 40px;
		}
	
		.search-block-on-ticket-page {
			padding-top: 40px;
			padding-bottom: 40px;
		}
	
		.search-title {
			font-family: OnestRegular;
			font-style: normal;
			font-weight: 700;
			font-size: 35px;
			line-height: 40px;
			padding-bottom: 30px;
		}
	
		.search-form {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
		}
	
		.search-form-fields {
			width: 100%;
			height: 243px;
			background-color: #FFFFFF;
			border-radius: 15px;
			margin-bottom: 10px;
			color: #A4A3B0;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
		}
	
		.form-field {
			width: 100%;
			height: 60px;
			padding-left: 15px;
			padding-right: 15px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
	
		.form-field-from {
			border-bottom: 1px solid #DDDEE5;
			font-family: 'OnestThin';
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 23px;
			color: #A4A3B0;
		}
	
		.form-field-to {
			border-bottom: 1px solid #DDDEE5;
			font-family: 'OnestThin';
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 23px;
			color: #A4A3B0;
		}
	
		.form-field-date {
			border-bottom: 1px solid #DDDEE5;
			font-family: 'OnestThin';
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 15px;
			color: #A4A3B0;
			
		}
	
		.form-field-place {
			border: none;
			font-family: 'OnestThin';
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 15px;
			color: #A4A3B0;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
	
		.form-field-place-counter {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}
	
		.search-form-button {
			width: 100%;
			height: 60px;
			background-color: #31CA57;
			border-radius: 15px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			border: none;
			color: #FFFFFF;
		}

		.search-form-button[disabled] {
			width: 100%;
			height: 60px;
			background-color: #2fad4a;
			border-radius: 15px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			border: none;
			color: #FFFFFF;
		}
	
		.search-form-button-icon {
			padding-right: 8px;
		}
	
		.search-form-button-title {
			font-family: 'OnestRegular';
			font-style: normal;
			font-weight: 700;
			font-size: 18px;
			line-height: 23px;
		}
	
		.search-form-button-link {
			width: 100%;
			text-decoration: none;
			color: #FFFFFF;
		}
	}