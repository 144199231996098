/* input {
    padding-left: 50px;
} */

.checkout-invoice {
    margin-top: 40px;
}

.checkout-info-total-passenger, .checkout-info-ticket-price {
    font-family: 'OnestThin';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.checkout-info-ticket-price {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 13px;
}

.checkout-info-ticket-price-doted {
    width: 100%;
    border-bottom: 1px dotted;
    height: 10px;
}   

.checkout-promocode {
    width: 100%;
    margin-top: 25px;
}

.checkout-promocode-value {
    width: calc(100% - 146px);
    height: 45px;
    background: #FFFFFF;    
    border: 1px solid #DDDEE5;
    border-radius: 5px;
    padding-left: 15px;
    font-family: 'OnestRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
}

.checkout-promocode-button {
    margin-left: 10px;
    width: 132px;
    height: 45px;
    background: #FFFFFF;
    border: 2px solid #31CA57;
    border-radius: 5px;
    font-family: 'OnestRegular';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #31CA57;
}

.checkout-total-price {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 25px;
    font-family: 'OnestRegular';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
}

.checkout-confirm {
    margin-top: 28px;
    margin-bottom: 80px;
}

.checkout-confirm-button {
    width: 100%;
    height: 60px;
    background: #31CA57;
    border-radius: 10px;
    border: none;
    font-family: 'OnestRegular';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkout-confirm-button[disabled] {
    width: 100%;
    height: 60px;
    background: grey;
    border-radius: 10px;
    border: none;
    font-family: 'OnestRegular';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkout-policy {
    margin-top: 15px;
}

.checkout-policy-title {
    font-family: 'OnestThin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #A4A3B0;
    margin-top: 15px;
}

.checkout-policy-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 7px;
}

.checkout-policy-item {
    font-family: 'OnestThin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.checkout-policy-item-dot {
    padding-right: 7px;
}

.checkout-policy-interactive {
    color: #31CA57;
}

.checkout-policy-link {
    text-decoration-line: underline;
    color: #31CA57;
    text-decoration: none;
}

@media  screen and (min-width: 525px) {
    .checkout-invoice {
        margin-top: 80px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 100px;
    }

    .checkout-info-promocode {
        width: 480px;
        margin-right: 70px;
    }

    .checkout-info-ticket-price-doted {
        width: 100%;
        border-bottom: 1px dotted;
        height: 10px;
    } 

    .checkout-promocode-button {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        color: #31CA57;
    }

    .checkout-promocode-value {
        width: 210px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #DDDEE5;
        padding-left: 18px;
        font-size: 18px;
        line-height: 23px;
    }

    .checkout-total-price {
        width: 230px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-right: 40px;
        margin-top: 0px;
    }

    .checkout-confirm {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .checkout-confirm-button {
        width: 360px;
        height: 80px;
        background: #31CA57;
        border-radius: 15px;
        font-size: 23px;
    }

    .checkout-policy {
        margin-top: 19px;
    }

    .checkout-policy-item, .checkout-policy-item-dot, .checkout-policy-interactive, .checkout-policy-link{
        font-size: 14px;
    }
}
