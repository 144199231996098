.user-account {
  width: 100%;
}
.user-account-info-table {
  width: 100%;
}
.user-account-info-table-head {
}
.user-account-menu-list {
  display: flex;
  justify-content: row;
  flex-direction: flex-start;
  align-items: center;
}

.user-account-menu-item {
  margin-right: 30px;
  margin-bottom: 30px;
  margin-top: 30px;
}

/* New style  */

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
  cursor: pointer;
  display: block;
}
button {
  cursor: pointer;
  direction: none;
}
.desctop-menu {
  display: none;
  /* visibility: hidden;
    width: 0;
    height: 0; */
}
.user-table-empty-title {
  font-family: "OnestThin";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.user-table-empty-button-error {
  margin-top: 50px;
}

.user-table-empty-button-error, .user-settings-logout {
  width: 305px;
  height: 45px;

  background: #31ca57;
  border-radius: 5px;
  border: none;
}

.user-table-empty-button-active:hover,
.user-table-empty-button-active:active,
.user-table-empty-button-active:focus,
.user-settings-logout:hover,
.user-settings-logout:active,
.user-settings-logout:focus {
  background-color: #25b849;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.user-settings-logout {
  font-family: "OnestRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.user-table-empty-link {
  font-family: "OnestRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}
.user-menu-block {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 417px;
}
.img-notfound {
  width: 90%;
  margin-top: 90px;
}
.select-menu {
  width: 345px;
  height: 45px;
  border: 1px solid #dddee5;
  border-radius: 5px;
  padding: 11px 225px 11px 15px;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  appearance: none;
  cursor: pointer;
}

.select-menu:focus {
  outline: none;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-color: #a4a3b0;
}
.option {
  font-family: "OnestThin";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #31ca57;
}
.option:hover,
.option:focus,
.option:active {
  color: #31ca57;
}

.select-wrapper {
  position: relative;
  width: 100%;
}
.img-select {
  position: absolute;
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  display: block;
  width: 14px;
  height: 7px;
  color: #a4a3b0;
  pointer-events: none;
}

.user-table-empty-image {
  width: 277px;
  margin-top: 87px;
}

.user-table-empty {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.user-menu-mobile {
  padding: 20px 15px 20px 15px;
  width: 100%;
  font-family: 'OnestRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}

.user-table-content {
  width: 100%;
  min-height: 543px;
}

.user-table-mobile-content-row {
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid #DDDEE5;
  border-bottom: 1px solid #DDDEE5;
}

.user-table-mobile-content-row-value {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.user-table-mobile-content-row-value-second {
  margin-top: 13px;
}

.user-table-mobile-content-row-value-third {
  margin-top: 19px;
}

.order-table-mobile-cell-1 {
  min-width: 140px;
}

.order-table-mobile-cell-2 {
  min-width: 98px;
}

.order-table-mobile-cell-3 {
  min-width: 107px;
}

.passenger-table-mobile-cell-1 {
  min-width: 140px;
}

.passenger-table-mobile-cell-2 {
  min-width: 137px;
}

.order-table-mobile-cell-title, .passenger-table-mobile-cell-title {
  font-family: 'OnestThin';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: #A4A3B0;
}

.order-table-mobile-cell-value, .passenger-table-mobile-cell-value {
  font-family: 'OnestRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  padding-top: 5px;
}

.order-table-mobile-cell-button, .passenger-table-mobile-cell-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.order-table-mobile-cell-button-image {
  padding-right: 6px;
}

.order-table-mobile-cell-button-title {
  font-family: 'OnestRegular';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #A4A3B0;
}

@media screen and (min-width: 1024px) {
  .order-table-column-order, .order-table-cell-order {
    width: 173px;
  }
  .order-table-column-from, .order-table-cell-from {
    width: 207px;
  }
  .order-table-column-to, .order-table-cell-to {
    width: 228px;
  }
  .order-table-column-date, .order-table-cell-date {
    width: 193px;
  }
  .order-table-column-total, .order-table-cell-total {
    /* width: 139px; */
    width: 190px;
  }
  .order-table-column-status, .order-table-cell-status {
    width: 137px;
  }

  .passenger-table-column-username, .passenger-table-cell-username {
    width: 232px;
  }

  .passenger-table-column-birth-date, .passenger-table-cell-birth-date {
    width: 196px;
  }

  .passenger-table-column-phone, .passenger-table-cell-phone {
    width: 221px;
  }

  .passenger-table-column-email, .passenger-table-cell-email {
    width: 379px;
  }

  .empty-passengers {
    width: 1180px;
    min-height: 543px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .user-table-empty {
    width: 1180px;
    min-height: 543px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .desctop-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .nav-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 1180px;
    margin-top: 60px;
  }
  .user-table-head {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-top: 2px solid #dddee5;

    background-color: #f2f3f6;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 60px;
    width: 100vw;
  }
  .user-table-head-column {
    width: 1180px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .user-table-head-column-title {
    font-family: "OnestThin";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #a4a3b0;
  }
  .text-navigation {
    font-family: "OnestRegular";
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 29px;
    margin-right: 70px;
  }
  .list-nav-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .text-navigation-1 {
    font-family: "OnestRegular";
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 29px;
    margin-right: 70px;
    color: #000000;
    height: 59px;
  }

  .line-green {
    color: #31ca57;
    border-bottom: 4px solid #31ca57;
  }

  .text-navigation:hover,
  .text-navigation:active,
  .text-navigation:focus {
    color: #31ca57;
  }
  .user-table-empty-title {
    font-family: "OnestThin";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    display: flex;
    justify-content: center;
    margin-top: 60px;
  }

  .user-table-empty-button-error {
    width: 365px;
    height: 50px;
    background: #31ca57;
    border-radius: 8px;
    border: none;
    font-family: "OnestRegular";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #ffffff;
    margin-top: 53px;
  }

  .user-table-empty-button-active:hover,
  .user-table-empty-button-active:active,
  .user-table-empty-button-active:focus {
    background-color: #25b849;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .user-table-empty-image {
    width: 397px;
    margin-top: 90px;
  }
  .mobile-menu {
    display: none;
  }

  .user-settings {
    min-height: 543px;
    width: 1180px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 50px;
  }

  .user-table-content {
    width: 100%;
    min-height: 543px;
  }

  .user-table-content-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #DDDEE5;
  }

  .user-table-content-row-value {
    width: 1180px;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    font-family: 'OnestThin';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
  }

  .order-table-cell-image {
    margin-left: 30px;
  }

  .order-table-cell-image-return {
    padding-top: 4px;
  }
}
