ul, ol{
    margin: 0;
    padding: 0;
    /* list-style: none; */
    }
h1, h2, h2 {
    font-weight: inherit;
    font-size:  inherit;
    font-family: "OnestRegular";
} 
  a {
    text-decoration: none;
    cursor: pointer;
    display: block;
  }
  button {
    cursor: pointer;
    direction: none;
  }
.content {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 60px;
    margin-left: 15px;
    margin-right: 15px;
}
.title-text {
    color: #ffffff;
    font-family: "OnestRegular";
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 40px;
    width: 100vw;
    margin-left: auto;
    color: #ffffff;
}
.title-text-1 {
    font-family: "OnestRegular";
    font-style: normal;
    font-weight: 700;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
}
.title-text-2 {
    font-family: "OnestRegular";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
}
.title-text-3 {
    font-family: "OnestRegular";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
}

.paragraph {
    font-family: "OnestRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

}

.paragraph {
    font-family: "OnestRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 10px;
}
.box {
    margin-left: 20px;
}




@media screen and (min-width: 1024px) {
.content {
    display: flex;
    flex-direction: column;
    width: 1180px;
    margin-top: 60px;
    margin-bottom: 100px;
}
.title {
    width: 100vw;
    background-color: #273041;
    margin-left: auto;
    margin-bottom: 50px;
    /* height: 20%; */
}
.title-text {
    color: #ffffff;
    font-family: "OnestRegular";
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 70px;
    width: 100vw;
    background-color: #273041;
}
.title-text-1 {
    font-family: "OnestRegular";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 51px;
}
.title-text-2 {
    font-family: "OnestRegular";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
}
.title-text-3 {
    font-family: "OnestRegular";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
}
.paragraph {
    font-family: "OnestRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 10px;
}
.box {
    margin-left: 20px;
}
}