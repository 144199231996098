.checkout-ticket-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #F2F3F6;
}

.checkout-ticket-block-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: 'OnestRegular';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.checkout-ticket {
    width: 100%;
    background: #F2F3F6;
    border-radius: 15px;
    margin-top: 15px;
}

.checkout-ticket-cancel {
    font-family: 'OnestRegular';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #A4A3B0;
    text-decoration: none;
    outline: none;
}

.checkout-ticket-item {
    background-color: white;;
}

.ticket-time-arrival-point-desktop {
    display: none;
}


@media  screen and (min-width: 525px) {
    .checkout-ticket-block-title {
        width: 1180px;
    }

    .checkout-ticket {
        width: 1180px;
    }

    .checkout-proposal-desktop {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .ticket-time-arrival-point-desktop {
        width: 380px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .checkout-ticket-cancel {
        font-size: 16px;
        line-height: 20px;
    }

    .checkout-promocode-value {
        width: 210px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #DDDEE5;
        border-radius: 8px;
    }
}