.section-404 {
    background-color:  #273041;
    width: 100vw;
    /* height: 100vh; */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.img-error {
    width: 80%;
    margin-top: 115px;
}
.text {
    font-family: "OnestRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    
    text-align: center;
    color: #FFFFFF;
    padding-top: 26px;
    padding-bottom: 49px;
}
.button-error {
    width: 305px;
    height: 60px;
    left: 35px;
    top: 411px;

    background: #31CA57;
    border-radius: 10px;

    font-family: "OnestRegular";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;

    color: #FFFFFF;

    padding: 18px 42px 19px 41px;
    margin-left: 35px;
    margin-right: 35px;
    margin-bottom: 170px;
}

@media screen and (min-width: 1024px) {
    .section-404 {
        background-color:  #273041;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .section-404 img {
        width: 1130px;
    }

    .text {
        font-family: "OnestRegular";
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 29px;

        color: #FFFFFF;
        padding-top: 37px;
        padding-bottom: 55px;
    }
    .button-error {
        width: 383px;
        height: 80px;
        left: 768px;
        top: 557px;

        background: #31CA57;
        border-radius: 15px;
        border: none;

        font-family: "OnestRegular";
        font-style: normal;
        font-weight: 700;
        font-size: 23px;
        line-height: 29px;

        color: #FFFFFF;

        padding: 25px 50px 26px 50px;
    }
}