
@media  screen and (max-width: 525px) {
    .fake-input {

    }

    .fake-input-title {

    }

    .fake-input-value {
        
    }
    
    .auth {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        margin-bottom: 40px;
        border-radius: 15px;
        border: 1px solid #DDDEE5;
    }

    .auth-info {
        display: none;
    }

    .auth-menu {
        width: 345px;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .auth-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0px 20px;
    }

    /* .auth-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    } */

    /* .auth-form p {
        font-family: "OnestThin";
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        padding: 0px;
        margin: 0px;
    } */

    .auth-controller {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; 
        padding-bottom: 15px;
    }

    .auth-controller label {
        font-family: "OnestThin";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        padding-bottom: 15px;
    }

    .auth-controller input {
        width: 420px;
        height: 50px;
        border-radius: 15px;
        padding-left: 0px;
        border: 1px solid #DDDEE5;
    }

    .auth-submit {
        width: 305px;
        height: 60px;
        border-radius: 15px;
        background-color: #31CA57;
        color: white;
        border: 2px solid #31CA57;
        margin-top: 40px;
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        color: #FFFFFF;
    }

    .auth-redirect-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .auth-redirect-button {
        width: 420px;
        height: 50px;
        border-radius: 15px;
        border: none;
        border: 1px solid #31CA57;
        margin-bottom: 15px;
        background: #ECECF0;
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        color: #31CA57;
    }

    .auth-redirect-button a {
        width: 430px;
        display: block;
        color: #31CA57;
    }

    input[type="password" i] {
        padding: 0px;
    }

    .auth-remember {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
    }

    .auth-remember input {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }

    .remember-me {
        height: 20px;
        font-family: 'Onest';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #31CA57;
    }

    .auth-choose {
        width: 305px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 23px;
    }


    .user-input-wrp {
        /* width: 305px; */
        height: 60px;
        border: 1px solid black;
        border-radius: 15px;
        position: relative;
        margin-top: 15px;
    }
    .user-input-wrp .inputText{
        width: 280px;
        max-height: 80px;
        outline: none;
        border:none;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 20px;
        box-shadow: none !important;
    }

    .user-input-wrp .floating-label {
        position: absolute;
        pointer-events: none;
        top: 23px;
        left: 15px;
        transition: 0.2s ease all;
    }
    .user-input-wrp input:focus ~ .floating-label,
    .user-input-wrp input:not(:focus):valid ~ .floating-label{
        top: 6px;
        left: 15px;
        font-size: 12px;
        opacity: 1;
    }

    .floating-label {
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 15px;
        color: #A4A3B0;
    }

    .inputText {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        color: #000000;
    }

    .forgot-password {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #31CA57;
    }

    .other-options {
        width: 305px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .register-account {
        width: 100%;
        /* background-image: url('/Users/zharkooleg/projects/bus4trip/html/new/image/svg/line.svg'); */
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 43px;
    }

    .register-account-title {
        background-color: #FFFFFF;
        padding: 0px 10px 0px 10px;

        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
    }

    .register-account-link {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        color: #31CA57;
        margin-top: 30px;
        text-decoration: none;
    }

    .phone-code-notification {
        margin-top: 20px;
    }

    .phone-code-resend-button[disabled] {
        color: #A4A3B0;
    }

    .phone-code-resend-button {
        margin-top: 15px;
        background-color: #FFFFFF;
        border: none;
        color: #31CA57;
    }

    .fake-input {
        width: 100%;
        height: 60px;
        border: 1px solid black;
        border-radius: 15px;
        position: relative;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .fake-input-title {
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        line-height: 29px;
        color: #A4A3B0;
        font-size: 12px;
        margin-left: 15px;
    }

    .fake-input-value {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        color: #000000;
        margin-left: 15px;
    }
}

@media  screen and (min-width: 525px) {
    .fake-input {
        width: 420px;
        height: 80px;
        border: 1px solid black;
        border-radius: 15px;
        position: relative;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .fake-input-title {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        line-height: 29px;
        color: #000000;
        font-size: 13px;
        margin-left: 15px;
    }

    .fake-input-value {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 29px;
        color: #000000;
        margin-left: 15px;
    }

    .auth {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 1180px;
        padding-top: 80px;
        padding-bottom: 80px;
        margin-top: 80px;
        margin-bottom: 80px;
        border-radius: 15px;
        border: 1px solid #DDDEE5;
        min-height: 680px;
    }

    .auth-info {
        width: 580px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .auth-menu {
        width: 580px;
        height: 100%;
    }

    .auth-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    /* .auth-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    } */

    /* .auth-form p {
        font-family: "OnestThin";
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        padding: 0px;
        margin: 0px;
    } */

    .auth-controller {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; 
        padding-bottom: 15px;
    }

    .auth-controller label {
        font-family: "OnestThin";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        padding-bottom: 15px;
    }

    .auth-controller input {
        width: 420px;
        height: 50px;
        border-radius: 15px;
        padding-left: 0px;
        border: 1px solid #DDDEE5;
    }

    .auth-submit {
        width: 420px;
        height: 80px;
        border-radius: 15px;
        background-color: #31CA57;
        color: white;
        border: 2px solid #31CA57;
        margin-top: 40px;
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 700;
        font-size: 23px;
        line-height: 29px;
        color: #FFFFFF;
    }

    .auth-redirect-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .auth-redirect-button {
        width: 420px;
        height: 50px;
        border-radius: 15px;
        border: none;
        border: 1px solid #31CA57;
        margin-bottom: 15px;
        background: #ECECF0;
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        color: #31CA57;
    }

    .auth-redirect-button a {
        width: 430px;
        display: block;
        color: #31CA57;
    }

    input[type="password" i] {
        padding: 0px;
    }

    .auth-remember {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-family: "OnestThin";
        font-style: normal;
        font-weight: 400;
    }

    .auth-remember input {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }

    .remember-me {
        height: 20px;
    }

    .auth-choose {
        width: 420px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }


    .user-input-wrp {
        width: 420px;
        border: 1px solid black;
        border-radius: 15px;
        position: relative;
        margin-top: 15px;
    }
    .user-input-wrp .inputText{
        width: 360px;
        max-height: 80px;
        outline: none;
        border: none;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 15px;
        margin-top: 12px;
        box-shadow: none !important;
        padding: 0px;
    }

    .user-input-wrp .floating-label {
        position: absolute;
        pointer-events: none;
        top: 25px;
        left: 15px;
        transition: 0.2s ease all;
    }
    .user-input-wrp input:focus ~ .floating-label,
    .user-input-wrp input:not(:focus):valid ~ .floating-label{
        top: 15px;
        left: 20px;
        font-size: 13px;
        opacity: 1;
    }

    .floating-label {
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 29px;
        color: #A4A3B0;
    }

    .inputText {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 29px;
        color: #000000;
    }

    .forgot-password {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #31CA57;
    }

    .other-options {
        width: 420px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .register-account {
        width: 420px;
        /* background-image: url('/Users/zharkooleg/projects/bus4trip/html/new/image/svg/line.svg'); */
        background-size: 420px;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 43px;
    }

    /* @media screen and (min-width: 525px)
    .user-input-wrp .inputText {
        width: 360px;
        max-height: 80px;
        outline: none;
        border: none;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 15px;
        margin-top: 12px;
        box-shadow: none !important;
        padding: 0px;
    } */

    

    .register-account-title {
        background-color: #FFFFFF;
        padding: 0px 10px 0px 10px;

        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;        
        color: #000000;
    }

    .register-account-link {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 29px;
        text-align: center;
        color: #31CA57;
        margin-top: 30px;
        text-decoration: none;
    }

    .input:-webkit-autofill {transition: background-color 5000s ease-in-out 0s;}

    .phone-code-notification {
        width: 420px;
        text-align: center;
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        
        color: #000000;
        margin-top: 25px;
    }

    .phone-code-timer {
        margin-top: 10px;
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        color: #000000;
    }
    
    .phone-code-resend-button[disabled] {
        color: #A4A3B0;
    }

    .phone-code-resend-button {
        margin-top: 15px;
        background-color: #FFFFFF;
        border: none;
        color: #31CA57;
    }
}
