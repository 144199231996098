/* MOBILE */

@media  screen and (max-width: 525px) {

    .ticket-sort {
        display: none;
    }

    .ticket-sort-departure {
        display: none;
    }

    .ticket-sort-in-the-way {
        display: none;
    }

    .ticket-sort-arrival {
        display: none;
    }

    .ticket-sort-free-places {
        display: none;
    }

    .ticket-sort-price {
        display: none;
    }

    .ticket-list {
        padding-top: 30px;
    }

    .ticket-item {
        width: 100%;
        border: 1px solid #DDDEE5;
        border-radius: 15px;
        padding: 15px;
        padding-bottom: 10px;
    }
    .ticket-proposal {
        width: 100%;
        border-top: 1px solid #DDDEE5;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
    }

    .ticket-head-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    
    .ticket-time {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 8px;
    }

    .ticket-time-departure {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .ticket-time-departure-time {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        color: #000000;
    }

    .ticket-time-departure-date {
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #A4A3B0;
    }
    
    .ticket-time-in-the-way {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 158px;
    }
    
    .ticket-time-in-the-way-block {
        width: 158px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        /* background-image: url('/Users/zharkooleg/projects/bus4trip/html/new/image/svg/arrow.svg'); */
        background-size: 157px;
        background-repeat: no-repeat;
        background-position: center;

        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #A4A3B0;
    }

    .ticket-time-in-the-way-image {
        padding-left: 10px;
        padding-right: 5px;
        background-color: #FFFFFF;
        height: 16px;
    }

    .ticket-time-in-the-way-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #FFFFFF;
        padding-right: 10px;
    }

    .ticket-time-in-the-way-type-mobile {
        height: 18px;
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        color: #A4A3B0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
    }

    .ticket-time-arrival {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
    }

    .ticket-time-arrival-time {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        color: #000000;
    }

    .ticket-time-arrival-date {
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #A4A3B0;
        text-align: right;
    }
    
    .ticket-proposal-place {
        /* display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end; */
    }

    .ticket-proposal-place-image {
        height: 15px;
        padding-right: 8px;
    }

    .ticket-proposal-mobile-title {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        color: #000000;
        padding-right: 4px;
    }

    .ticket-proposal-place-value {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
    }

    .ticket-proposal-price-value {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        color: #000000;
    }

    .ticket-short-map {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 14px;
    }

    .ticket-time-departure-point {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        color: #000000;
        min-width: 95px;
    }

    .ticket-time-transplantation-point {
        margin-left: 8px;
        margin-right: 8px;
        padding: 7px 11px 7px 11px;
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        line-height: 11px;
        text-align: center;
        color: #000000;
        background-color: #C1F3CD;
        border-radius: 6px;
        min-width: 125px;
    }

    .ticket-time-transplantation-point-duration {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 13px;
        text-align: center;
        color: #000000;
    }

    .ticket-time-arrival-point {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        color: #000000;
        text-align: right;
    }

    .ticket-controller {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-top: 15px;
    }

    .ticket-controller-more-info {
        width: 55px;
        height: 45px;
        left: 30px;
        top: 740px;
        background: #F2F3F6;
        border-radius: 5px;  
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ticket-controller-checkout {
        width: 250px;
        height: 45px;
        left: 95px;
        top: 740px;
        background: #31CA57;
        border-radius: 5px;
        border: none;

        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .ticket-info {
        /* padding-top: 20px; */
    }

    .ticket-info-title {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
        padding-bottom: 4px;
    }

    .ticket-info-value {
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #000000;
        margin-bottom: 10px;
    }

    .ticket-flight {
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .ticket-info-chapter {
        padding-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .ticket-info-full-road-map {
        padding-top: 20px;
    }

    .ticket-info-full-road-map-point-image {
        width: 13px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
    }

    .ticket-info-list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .ticket-info-full-road-map {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    .ticket-info-full-road-map-chapter {
        min-width: 85px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
    }

    .ticket-info-full-road-map-point-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .ticket-info-full-road-map-point-detail {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .point-time {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
    }

    .point-title {
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #000000;
    }
    
    .point-transplantation {
        width: 196px;
        height: 25px;
        background: #C1F3CD;
        border-radius: 6px;
        margin-top: 5px;
        margin-bottom: 8px;

        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        line-height: 11px;        
        color: #000000;
        
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .point-transplantation-title {
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        line-height: 11px;
        color: #000000;        
        padding-right: 6px;
    }

    .point-transplantation-duration {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 13px;
        color: #000000;
    }

    .ticket-service {
        padding-top: 20px;
    }

    .ticket-service-title {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
    }

    .ticket-service-list {
        padding-top: 15px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .ticket-service-item {
        padding-right: 9px;
        padding-bottom: 9px;
    }

    .ticket-service-item-image {
        min-width: 45px;
        min-height: 45px;
    }

    .ticket-proposal-desktop {
        display: none;
    }

    .ticket-controller-more-title {
        display: none;
    }

    .ticket-info-full-road-map-point-image-line-mobile {
        display: block;
    }

    .ticket-info-full-road-map-point-image-line-desktop {
        display: none;
    }

    .ticket-service-desktop {
        display:  none;
    }

    .point-transplantation-block {
        margin-left: 105px;
    }

    .ticket-service-mobile {
        padding-top: 20px;
    }

    .ticket-time-without-bus-change {
        display: none;
    }

    .loaderRoot {
        min-height: 400px;
    }

    .loaderStyle {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

/* DESKTOP */
@media  screen and (min-width: 525px) {
    .ticket-sort {
        width: 100%;
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        color: #A4A3B0;
        padding-left: 30px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-top: 50px;
        padding-bottom: 15px;
    }

    .ticket-sort-departure {
        width: 305px;
    }
    .ticket-sort-in-the-way {
        width: 186px;
    }
    .ticket-sort-arrival {
        width: 308px;
    }
    .ticket-sort-free-places {
        width: 159px;
    }
    .ticket-sort-price {

    }

    .ticket-list {
        padding-top: 30px;
    }

    .ticket-item {
        width: 100%;
        border: 1px solid #DDDEE5;
        border-radius: 15px;
        padding: 30px;
        margin-bottom: 15px;
    }

    .ticket-head-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    
    .ticket-time {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 12px;
    }

    .ticket-time-departure {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        width: 220px;
    }

    .ticket-time-departure-time {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 30px;
        color: #000000;
        padding-right: 10px;
    }

    .ticket-time-departure-date {
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        color: #A4A3B0;
    }
    
    .ticket-time-in-the-way {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 250px;
        margin-right: 20px;
    }
    
    .ticket-time-in-the-way-block {
        width: 250px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        /* background-image: url('/Users/zharkooleg/projects/bus4trip/html/new/image/svg/arrow.svg'); */
        background-size: 250px;
        background-repeat: no-repeat;
        background-position: center;

        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 29px;
        color: #A4A3B0;
    }

    .ticket-time-in-the-way-image {
        padding-left: 10px;
        padding-right: 5px;
        background-color: #FFFFFF;

    }

    .ticket-time-in-the-way-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #FFFFFF;
        padding-right: 10px;
        padding-left: 10px;
    }

    .ticket-time-in-the-way-type-mobile {
        display:  none;
        /* height: 18px;
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        color: #A4A3B0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end; */
    }

    .ticket-time-arrival {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        width: 230px;
        margin-right: 78px;
    }

    .ticket-time-arrival-time {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 30px;
        color: #000000;
        padding-right: 10px;
    }

    .ticket-time-arrival-date {
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        color: #A4A3B0;
    }
    
    .ticket-proposal {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        padding-top: 10px;
    }

    .ticket-proposal-place {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 159px;
    }

    .ticket-proposal-place-image {
        height: 21px;
        padding-right: 12px;
    }

    .ticket-proposal-place-value {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 23px;
        color: #000000;
    }

    .ticket-proposal-price-value {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 30px;
        color: #000000;
    }

    .ticket-short-map {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        padding-bottom: 14px;
    }

    .ticket-time-departure-point {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
        width: 245px;
        margin-right: 20px;
    }

    .ticket-time-transplantation-point {
        padding: 7px 10px 7px 10px;
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #000000;
        background-color: #C1F3CD;
        border-radius: 6px;
        width: 176px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 66px;
    }

    .ticket-time-transplantation-point-duration {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #000000;
    }

    .ticket-time-arrival-point {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
        text-align: left;
        width: 230px;
    }

    .ticket-controller {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-top: 15px;
        /* padding-bottom: 30px;
        border-bottom: 1px solid #DDDEE5; */
    }

    .ticket-controller-more-info {
        width: 310px;
        height: 50px;
        background: #F2F3F6;
        border-radius: 5px;  
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ticket-controller-more-title {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        padding-right: 10px;
    }

    .ticket-controller-more-image {
        height: 9px;
    }

    .ticket-controller-checkout {
        width: 247px;
        height: 50px;
        left: 95px;
        top: 740px;
        background: #31CA57;
        border-radius: 5px;
        border: none;

        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .ticket-info {
        /* padding-top: 20px; */
    }

    .ticket-info-title {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        padding-bottom: 4px;
    }

    .ticket-info-value {
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
        margin-bottom: 14px;
    }

    .ticket-flight {
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        color: #000000;
        padding-top: 30px;
        padding-bottom: 35px;
    }

    .ticket-info-chapter {
        padding-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .ticket-info-full-road-map {
        padding-top: 20px;
    }

    .ticket-info-full-road-map-point-image {
        width: 13px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
    }

    .ticket-info-list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .ticket-info-full-road-map {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    .ticket-info-full-road-map-chapter {
        width: 120px;
        margin-right: 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
    }

    .ticket-info-full-road-map-point-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .ticket-info-full-road-map-point-detail {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .point-time {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        padding-bottom: 5px;
    }

    .point-title {
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
        width: 345px;
        padding-bottom: 20px;
    }

    .point-transplantation {
        width: 262px;
        height: 38px;
        background: #C1F3CD;
        border-radius: 6px;
        margin-top: 5px;
        margin-bottom: 8px;

        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        line-height: 11px;        
        color: #000000;
        
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .point-transplantation-title {
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #000000;        
        padding-right: 6px;
    }

    .point-transplantation-duration {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
    }

    .ticket-service-mobile {
        display: none;
    }

    .ticket-service-title {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
    }

    .ticket-service-list {
        padding-top: 15px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .ticket-service-item {
        margin-right: 10px;
    }

    .ticket-service-item-image {
        min-width: 45px;
        min-height: 45px;
        margin-bottom: 10px;
    }

    .ticket-proposal-mobile {
        display: none;
    }

    .ticket-chpter-and-road-map {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
    }

    .ticket-info-full-road-map-point-image-dot {
        padding-bottom: 6px;
    }

    .ticket-info-full-road-map-point-image-line-mobile {
        display: none;
    }

    .ticket-info-full-road-map-point-image-line-desktop {
        display: block;
    }

    .chapter {
        width: 346px;
    }

    .point-transplantation-block {
        margin-left: 179px;
    }

    .ticket-service-desktop {
        margin-top: 10px;
    }


    .ticket-time-without-bus-change {
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        text-align: center;
        color: #000000;
        border-radius: 6px;
        width: 165px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 64px;
    }

    .loaderRoot {
        min-height: 400px;
    }

    .loaderStyle {
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
    }
}