/* Блок з лого та заголовком або допоміжними частинами сторінки */

@media  screen and (min-width: 525px) {
	.header-container {
		background-color: #273041;
		width: 100%;
	    display: flex;
	    flex-direction: column; 
	    justify-content: center; 
	    align-items: center;
	    color: #FFFFFF;
	}

	.header {
		width: 1180px;
		padding-top: 20px;
	    display: flex;
	    flex-direction: row; 
	    justify-content: space-between; 
	    align-items: center; 
	}

	.company-logo {
		height: 54px;
	    display: flex;
	    flex-direction: row;
	    justify-content: flex-start;
	    align-items: center;
	}

	.logo-text {
		padding-left: 15px;
	}

	.company-title {
		font-family: "OnestRegular";
		font-size: 30px;
		font-weight: 400;
		line-height: 38px;
		letter-spacing: 0em;
		text-align: left;
	}

	.company-slogan {
		font-family: "OnestThin";
		font-size: 14px;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: 0em;
		text-align: left;
	}

	.company-business-card {
	    display: flex;
	    flex-direction: row;
	    justify-content: flex-start;
	    align-items: center;
	    height: 56px;
	}

	.business-card-desktop {
		
		padding-right: 30px;
	    display: flex;
	    flex-direction: column;
	    justify-content: flex-start;
	    align-items: start;
	}

	.business-card-mobile {
		display: none;
	}

	.business-card-title {
		font-family: OnestThin;
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: left;
	}

	.business-card-number {
		font-family: OnestRegular;
		font-size: 23px;
		font-weight: 500;
		line-height: 29px;
		letter-spacing: 0em;
		text-align: left;
		color: #FFFFFF;
	}

	.business-card-number-link {
		text-decoration: none;
		color: #FFFFFF;
	}

	.business-card-user {
		width: 56px;
		height: 56px;
		max-width: 56px;
		max-height: 56px;
	}
}


@media  screen and (max-width: 525px) {
	.header-container {
		background-color: #273041;
		width: 100%;
        padding-left: 15px;
        padding-right: 15px;
	    display: flex;
	    flex-direction: column; 
	    justify-content: center; 
	    align-items: center;
	    color: #FFFFFF;
	}

	.header {
		width: 100%;
		padding-top: 15px;
	    display: flex;
	    flex-direction: row; 
	    justify-content: space-between; 
	    align-items: center; 
	}

	.company-logo {
	    display: flex;
	    flex-direction: row;
	    justify-content: flex-start;
	    align-items: center;
	}

	.logo {
		width: 57px;
		height: 37px;
	}

	.logo-text {
		padding-left: 10px;
	}

	.company-title {
		font-family: 'OnestRegular';
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 25px;
	}

	.company-slogan {
		font-family: 'OnestThin';
		font-style: normal;
		font-weight: 400;
		font-size: 10px;
		line-height: 13px;
		color: #FFFFFF;
	}

	.company-business-card {
	    display: flex;
	    flex-direction: row;
	    justify-content: flex-start;
	    align-items: center;
	}

	.business-card-desktop {
		display: none;
	}

	.business-card-mobile {
		margin-top: 15px;
		padding-top: 12px;
		width: 100%;
	    display: flex;
	    flex-direction: row;
	    justify-content: space-between;
	    align-items: center;
		border-top: 1px solid #3C465A;
	}

	.business-card-title {
		font-family: 'OnestThin';
		font-size: 12px;
		font-weight: 400;
		line-height: 13px;
		letter-spacing: 0em;
		text-align: left;
		width: 118px;
	}

	.business-card-number {
		font-family: "OnestRegular";
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 23px;
		width: 184px;
		color: #FFFFFF;
	}

	.business-card-user {
		width: 40px;
		height: 40px;
		max-width: 40px;
		max-height: 40px;
	}

	.business-card-number-link {
		text-decoration: none;
		color: #FFFFFF;
	}
}