.step-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 40px 0px 30px 0px;
}

.step-line {
  width: 100%;
  height: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.step-line-start {
  background: linear-gradient(90deg, #31ca5787 0%, #323d50 30%);
  border-radius: 20px;
}

.step-line-final {
  background: #31ca5763;
  border-radius: 20px;
}

.step-line-point {
  width: 11px;
  height: 11px;
  background: #515e76;
  border-radius: 50%;
}

.step-line-point-active {
  width: 15px;
  height: 15px;
  background: #31ca57db;
  border-radius: 50%;
}

.step-line-title {
  width: 100%;
  font-family: "OnestRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #404c61;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.step-line-ttile-active {
  color: #ffffff;
}

.step-line-title-first,
.step-line-title-second,
.step-line-title-third {
  width: 33%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.step-line-title-first {
  justify-content: flex-start;
}

.step-line-title-second {
  justify-content: center;
}

.step-line-title-third {
  justify-content: flex-end;
}

@media screen and (min-width: 525px) {
  .step-block {
    width: 780px;
    margin: 60px 0px 30px 0px;
  }

  .step-line {
    height: 20px;
  }

  .step-line-point {
    width: 14px;
    height: 14px;
  }

  .step-line-point-active {
    width: 20px;
    height: 20px;
  }

  .step-line-title {
    font-style: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    margin-top: 13px;
  }
}
