.page-info-area {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #273041;
    width: 100%;
    padding-bottom: 40px;
}

.page-title {
    width: 1130px;
}

.page-title h1 {
    font-family: "OnestThin";
    font-style: "normal";
    font-weight: 700;
    font-size: 60px;
    color: #FFFFFF;
    margin: 0px;
    padding: 0px;
}