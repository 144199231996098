.checkout-timer-block {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'OnestRegular';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    background-color:  #C1F3CD;
}

.checkout-timer-image {
    width: 15px;
    height: 15px;
    margin-right: 7px;
}

.checkout-timer-instruction {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}


@media  screen and (min-width: 525px) {
    .checkout-timer-block {
        font-size: 16px;
        line-height: 20px;
        height: 50px;
    }
}

