@media  screen and (max-width: 525px) {
	.error-404-block {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 117px 35px 170px 35px;
    }

	.error-404-image {
        width: 214px;
        height: 92px;
    }

	.error-404-title {
        margin-top: 26px;
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        color: #FFFFFF;
    }
    
    .error-404-link-return {
        margin-top: 49px;
    }

	.error-404-button {
        width: 305px;
        height: 60px;
        background: #31CA57;
        border-radius: 10px;
        text-decoration: none;
        border: none;
    }

    .error-404-button-title {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        color: #FFFFFF;
    }

    
}

@media  screen and (min-width: 525px) {
	.error-404-block {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 190px 0px 163px 0px;
    }

	.error-404-image {
        width: 415px;
        height: 180px;
    }

	.error-404-title {
        margin-top: 26px;
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 29px;
        text-align: center;
        color: #FFFFFF;
    } 

    .error-404-link-return {
        margin-top: 49px;
    }

	.error-404-button {
        width: 383px;
        height: 80px;
        background: #31CA57;
        border-radius: 10px;
        text-decoration: none;
        border: none;
    }

    .error-404-button-title {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        color: #FFFFFF;
    }
}