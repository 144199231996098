.confirm {
  width: 740px;
  background: #fff;
  transition: 0s;
  z-index: 100;
  position: absolute;
  top: -100%;
  background: #ffffff;
  border-radius: 40px;
}

.confirm.show {
  top: calc(216px);
}

.confirm-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--primary);
  padding: 1.5rem;
}

.overlay {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.confirm.show + .overlay {
  display: block;
}

.return-ticket-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 70px;
}

.return-ticket-content-title {
  font-family: "OnestRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #000000;
}

.return-ticket-content-block {
  margin-top: 32px;
}

.return-ticket-content-info {
  font-family: "OnestRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  padding-top: 18px;
}

.return-ticket-content-button {
  width: 272px;
  height: 50px;
  background: #31ca57;
  border-radius: 8px;
  border: none;
  font-family: "OnestRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
  margin-top: 47px;
}

@media screen and (max-width: 450px) {
  .confirm {
    width: 95%;
    top: -100%;
    border-radius: 20px;
  }

  .confirm.show {
    top: calc(162px);
  }

  .confirm-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--primary);
  }

  .return-ticket-content {
    padding: 30px 20px 35px 20px;
  }

  
  .return-ticket-content-title {
    font-family: 'OnestRegular';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #000000; 
  }

  .return-ticket-content-info {
    font-family: "OnestRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    padding-top: 18px;
  }

  .return-ticket-content-block {
    margin-top: 20px;
  }

  .return-ticket-content-button {
    width: 305px;
    height: 45px;
    background: #31ca57;
    border-radius: 8px;
    border: none;
    font-family: "OnestRegular";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 30px;
  }
}
