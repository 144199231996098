.booking-block {
    margin-top: 30px;
    margin-bottom: 60px;
    padding: 30px 20px 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #F2F3F6;
    border-radius: 15px;
}

.booking-info {
    width: 100%;
}

.booking-logo-desktop {
    display: none;
}

.booking-info-title {
    font-family: 'OnestRegular';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    width: 100%;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.booking-info-title-value {
    width: 275px;
}

.booking-info-text-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: 'OnestThin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #000000;
    margin-top: 35px;
}

.booking-info-text-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: 'OnestThin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #000000;
    margin-top: 14px;
}


.booking-info-text {
    font-family: 'OnestThin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #000000;
}

.booking-logo-mobile {
    margin-top: 33px;
}

.booking-image {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.booking-info-user-value {

}

.booking-info-controller-login {
    width: 100%;
    height: 45px;
    background: #31CA57;
    border-radius: 5px;
    font-family: 'OnestRegular';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}

.booking-info-controller-main-page {
    width: 100%;
    height: 45px;
    border: 2px solid #31CA57;
    border-radius: 5px;
    font-family: 'OnestRegular';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #31CA57;
}

.booking-info-user {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: 'OnestThin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #000000;
}

.booking-info-user-value {
    font-family: 'OnestRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #000000;
}

.booking-info-controller {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 35px;
}

.booking-info-controller-login {
    width: 100%;
    height: 45px;
    background: #31CA57;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.booking-info-controller-main-page {
    margin-top: 10px;
    width: 100%;
    height: 45px;
    border: 2px solid #31CA57;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

@media  screen and (min-width: 525px) { 
    .booking-block {
        width: 100%;
        margin-top: 50px;
        margin-bottom: 80px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        min-height: 630px;
    }

    .booking-info {
        width: 50%;
        padding: 50px 0px 100px 70px;
    }

    .booking-info-title {
        width: 100%;
        font-size: 30px;
        line-height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .booking-info-title-value {
        width: 400px;
    }

    .booking-info-user {
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        color: #000000;
    }

    .booking-info-user-value {
        font-size: 18px;
        line-height: 23px;
    }

    .booking-info-text-head {
        width: 100%;
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        color: #000000;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .booking-info-text-head-value {
        width: 480px;
    }

    .booking-logo-desktop {
        display: block;
    }

    .booking-logo-mobile {
        display: none;
    }

    .booking-logo-desktop {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
    }

    .booking-info-controller-login, .booking-info-controller-main-page {
        width: 342px;
        font-size: 20px;
        line-height: 25px;
    }
}