.checkout-buyer-block {
    margin-top: 30px;
}

.checkout-buyer-item-title {
    font-family: 'OnestRegular';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
}

.checkout-buyer-item-contact {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.checkout-buyer-item-note {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.checkout-buyer-item-contact-email {
    width: 100%;
}

.checkout-buyer-item-contact-phone {
    width: 100%;
}

.checkout-buyer-item-input-note {
    width: 100%;
}

@media  screen and (min-width: 525px) {
    .checkout-buyer-item-contact {
        width: 1180px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .checkout-buyer-item-contact-email {
        width: 580px;
    }

    .checkout-buyer-item-contact-phone {
        width: 580px;
    }

    .checkout-buyer-item-note {
        margin-top: 20px;
    }

    .checkout-buyer-item-input-note {
        width: 1180px;

    }

    .checkout-buyer-item-title {
        font-size: 30px;
        line-height: 38px;
    }

    .checkout-buyer-block {
        margin-top: 40px;
    }
}