input[type="text" i] {
    padding: 0px 0px;
}

.react-datepicker-wrapper input[type="text"]{
    border: none;
    outline: none;
    font-family: 'OnestRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
}

@media  screen and (min-width: 525px) {
    .react-datepicker-wrapper input[type="text"]{
        font-size: 23px;
    }
}









