@media  screen and (max-width: 525px) {
    .checkout-passenger-item {
        padding-top: 30px;
    }

    .checkout-passenger-block {

    }

    .checkout-passenger-list {
        border-bottom: 1px solid #DDDEE5;
        padding-bottom: 30px;
    }

    .checkout-passenger-item-place-title {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 10px;
        /* margin-top: 30px; */
    }

    .checkout-passenger-item-person {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        /* margin-top: 20px; */
    }

    .checkout-passenger-item-configuration {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .checkout-passenger-item-input-name {
        width: 100%;
    }

    .checkout-passenger-item-input-surname {
        width: 100%;
    }

    .checkout-passenger-item-input-birthdate {
        width: 100%;
        padding-left: 15px;
    }

    .checkout-passenger-item-input-birthdate-label,
    .checkout-passenger-item-input-baggage-label,
    .checkout-passenger-item-input-discount-label {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #A4A3B0;
    }

    .checkout-passenger-item-input-baggage-label,
    .checkout-passenger-item-input-discount-label {
        padding-left: 15px;
        height: 18px;
        display: block;
        padding-top: 6px;
    }

    .checkout-passenger-item-input-baggage,
    .checkout-passenger-item-input-discount {
        width: 100%;
    }

    .checkout-passenger-item-input-discount {
        width: 100%;
    }

    .checkout-passenger-item-select-baggage,
    .checkout-passenger-item-select-discount {

    }

}
@media  screen and (min-width: 525px) {
    .checkout-passenger-item {
        padding-top: 50px;
    }

    .checkout-passenger-item-place-title {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 30px;
        color: #000000;
        /* margin-top: 30px; */
    }

    .checkout-passenger-item-person {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }

    .checkout-passenger-item-input-name {
        width: 580px;
    }

    .checkout-passenger-item-input-surname {
        width: 580px;
    }

    .checkout-passenger-item-input-birthdate {
        width: 280px;
        padding-top: 6px;
        padding-left: 20px;
    }

    .checkout-passenger-item-input-birthdate-label, 
    .checkout-passenger-item-input-baggage-label,
    .checkout-passenger-item-input-discount-label {
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #A4A3B0;
    }

    .checkout-passenger-item-configuration {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }

    .checkout-passenger-item-input-baggage,
    .checkout-passenger-item-input-discount {
        width: 580px;
        padding-top: 6px;
    }

    .checkout-passenger-item-input-baggage-label,
    .checkout-passenger-item-input-discount-label {
        padding-left: 20px;
    }

    .checkout-passenger-item-select-baggage,
    .checkout-passenger-item-select-discount {
        width: 570px;
        padding-left: 10px;
    }

    .checkout-passenger-item-input-discount {
        width: 580px;
    }
}