@media  screen and (min-width: 525px) {

    .checkout-input-wraper {
        /* width: auto; */
        height: 80px;
        border: 1px solid #DDDEE5;
        border-radius: 15px;
        position: relative;
        margin-bottom: 0px;
    }

    .checkout-input-label {
        font-family: 'OnestThin';
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 29px;        
        color: #A4A3B0;
        
    }

    .checkout-input-wraper .checkout-input-field{
        outline: none;
        border:none;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 5px;
        box-shadow: none !important;
    }

    .checkout-input-wraper .checkout-input-label {
        position: absolute;
        pointer-events: none;
        top: 25px;
        left: 20px;
        transition: 0.2s ease all;
        font-size: 23px;
        line-height: 29px;
    }
    .checkout-input-wraper input:focus ~ .checkout-input-label,
    .checkout-input-wraper input:not(:focus):valid ~ .checkout-input-label{
        top: 5px;
        left: 20px;
        font-size: 13px;
        opacity: 1;
    }

    .checkout-input-field {
        border-radius: 10px;
        font-size: 23px;
        line-height: 29px;
    }
}

@media screen and (min-width: 525px) {
    .checkout-passenger-block {
        /* padding-top: 40px; */
        padding-bottom: 50px;
        border-bottom: 1px solid #DDDEE5;
    }
}

@media screen and (max-width: 525px) {
    .checkout-input-wraper {
        width: 100%;
        height: 60px;
        border: 1px solid #DDDEE5;
        border-radius: 10px;
        position: relative;
        margin-top: 10px;
        margin-bottom: 0px;
    }

    /* user-input-wrp inputText */
    .checkout-input-wraper .checkout-input-field{
        outline: none;
        border:none;
        padding-left: 15px;
        padding-right: 10px;
        padding-top: 3px;
        box-shadow: none !important;
    }

    .checkout-input-wraper .checkout-input-label {
        position: absolute;
        pointer-events: none;
        top: 18px;
        left: 15px;
        transition: 0.2s ease all;
        font-size: 18px;
        line-height: 23px;
        color: #A4A3B0;
    }

    .checkout-input-wraper input:focus ~ .checkout-input-label,
    .checkout-input-wraper input:not(:focus):valid ~ .checkout-input-label{
        top: 6px;
        left: 15px;
        font-size: 12px;
        opacity: 1;
    }

    .checkout-input-field {
        font-family: 'OnestRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        color: #000000;
        background: #FFFFFF;
        border: 1px solid #DDDEE5;
        border-radius: 10px;
    }
}